import { defineMessages } from 'react-intl'

// TODO: Use global messages instead of these?
const info = defineMessages({
  modalContinue: {
    id: 'spaBooking:common.modal.continue',
    defaultMessage: 'Continue',
    description: 'Common: Continue',
  },
  modalCancel: {
    id: 'spaBooking:common.modal.cancel',
    defaultMessage: 'Cancel',
    description: 'Common: Cancel',
  },
  modalTitleUpdateBooking: {
    id: 'spaBooking:common.modal.update.title',
    defaultMessage: 'Update booking',
    description: 'Common: Update booking',
  },
})

const warnings = defineMessages({
  modalResetState: {
    id: 'spaBooking:common.modal.resetState',
    defaultMessage: 'This change will reset your booking.',
    description:
      'Common: Notify the user that the change they are about to make will require the booking to be reset.',
  },
})

const errors = defineMessages({
  required: {
    id: 'spaBooking:common.errors.required',
    defaultMessage: 'Required',
    description: 'Common: Error when field is required',
  },
})

export const commonMessages = {
  info,
  warnings,
  errors,
}
