import { defineMessages } from 'react-intl'

const info = defineMessages({
  breadcrumbTitle: {
    id: 'spaBooking:admission.breadcrumbs.title',
    defaultMessage: 'Admission',
    description: 'Admission: Title of admission step breadcrumb',
  },
  breadcrumbSubtitle: {
    id: 'spaBooking:admission.breadcrumbs.subtitle',
    defaultMessage: '{admission}',
    description: 'Admission: Subtitle of admission step breadcrumb',
  },
  title: {
    id: 'spaBooking:admission.title',
    defaultMessage: 'Select your Blue Lagoon admission',
    description: 'Admission: Title of admission step',
  },
  retreatUpgradeCardTitle: {
    id: 'spaBooking:admission.retreatUpgradeCardTitle',
    defaultMessage: 'Luxury: Retreat Spa',
    description: 'Admission: Title of admission step',
  },
  retreatUpgradeCardSubtitle: {
    id: 'spaBooking:admission.retreatUpgradeCardSubtitle',
    defaultMessage: 'Price from {formattedPrice} per changing room (Fits 2)',
    description: 'Admission: subtitle of admission step',
  },
  retreatUpgradeCardContent: {
    id: 'spaBooking:admission.retreatUpgradeCardContent',
    defaultMessage:
      'The Retreat Spa is a 1000 fm (10764 sqft) luxurious spa at The Blue Lagoon premises. With 8 subterranean spaces, private changing rooms and access to the secluded Retreat Lagoon as well as The Blue Lagoon.',
    description: 'Admission: subtitle of admission step',
  },
  subscription: {
    id: 'spaBooking:admission.subscription',
    defaultMessage: 'Subscription',
    description: 'Admission: Subscription',
  },
  highlandbaseBaths: {
    id: 'spaBooking:admission.highlandbaseBaths',
    defaultMessage: 'Highlandbase Baths',
    description: 'Admission: Highlandbase Baths',
  },
  comfort: {
    id: 'spaBooking:admission.comfort',
    defaultMessage: 'Comfort',
    description: 'Admission: Comfort',
  },
  retreat: {
    id: 'spaBooking:admission.retreat',
    defaultMessage: 'Retreat',
    description: 'Admission: Retreat',
  },
  premium: {
    id: 'spaBooking:admission.premium',
    defaultMessage: 'Premium',
    description: 'Admission: Premium',
  },
  signature: {
    id: 'spaBooking:admission.signature',
    defaultMessage: 'Signature',
    description: 'Admission: Signature',
  },
  priceFromPerPerson: {
    id: 'spaBooking:admission.priceFromPerPerson',
    defaultMessage: 'Price from {formattedPrice} per person',
    description: 'Admission: Title of Premium admission step',
  },
  everythingInComfortAdmission: {
    id: 'spaBooking:admission.everythingInComfortAdmission',
    defaultMessage: 'Everything in Comfort admission',
    description: 'Admission: Everything in Comfort admission',
  },
  everythingInMembershipAdmission: {
    id: 'spaBooking:admission.everythingInMembershipAdmission',
    defaultMessage: 'Everything in Subscription admission',
    description: 'Admission: Everything in Subscription admission',
  },
  seasonal: {
    id: 'spaBooking:admission.seasonal',
    defaultMessage: 'Seasonal',
    description: 'Admission: Seasonal label',
  },
})

const warnings = defineMessages({
  priceRangeDisclaimer: {
    id: 'spaBooking:admission.priceRangeDisclaimer',
    defaultMessage: '*Price range per person on this day',
    description: 'Admission: Price range disclaimer',
  },
})

const errors = defineMessages({
  missingDate: {
    id: 'spaBooking:admission.errors.missingDate',
    defaultMessage: 'No date selected.',
    description: 'Admission: Error message when no date is selected',
  },
  invalidDate: {
    id: 'spaBooking:admission.errors.invalidDate',
    defaultMessage: 'Invalid arrival date',
    description: 'Admission: Error message when arrival date is invalid',
  },
  membershipLimitExceeded: {
    id: 'spaBooking:admission.errors.membershipLimitExceeded',
    defaultMessage:
      'You can only have five active bookings at a time. You need to cancel one of your active bookings on My Bluelagoon',
    description:
      'Admission: Error message when a membership customer has too many bookings',
  },
})

export const admissionMessages = {
  info,
  warnings,
  errors,
}
