import { useContext } from 'react'

import { CurrencyContext } from 'bl-common/src/context/Currency/CurrencyProvider'
import { useCartContext } from 'bl-common/src/hooks/useCartContext'
import { formatPrice } from 'bl-utils/src/currency/formatPrice'
import { displayDate } from 'bl-utils/src/formatting/formatDate'

import { Cart } from '../../../components/Cart'
import { globalBookingMessages } from '../../../messages'
import { timeMessages } from '../messages/time'

export const SpaCart = props => {
  const { cart } = useCartContext()
  const { currency } = useContext(CurrencyContext)
  const useMCPPayment = props.control.context.useMCPPayment
  const hideCurrencyChange =
    props.control.screen?.setupHook?.hideCurrencyChangeInCart

  const iskAmount = cart?.paymentAmount ?? 0

  const disclaimer = props.control.context.t(
    globalBookingMessages.text.spaCartIskDisclaimer,
    {
      amount: Number(iskAmount) ? formatPrice(iskAmount, 'ISK', false) : '—',
    }
  )

  const mcpDisclaimer = props.control.context.t(
    globalBookingMessages.text.spaCartMCPDisclaimer,
    {
      currency,
    }
  )
  return (
    <Cart
      {...props}
      hideCurrencyChange={useMCPPayment && hideCurrencyChange}
      displayFractionalPrices={useMCPPayment}
      disclaimer={useMCPPayment ? mcpDisclaimer : disclaimer}
      title={control => control.context.t(globalBookingMessages.text.cartTitle)}
      subtitle={control => {
        return control.context.t(timeMessages.info.cartSubtitle, {
          date: (
            <strong>
              {displayDate(
                control?.flow?.state?.calendar?.arrivalDate
                  ? new Date(control?.flow?.state?.calendar?.arrivalDate)
                  : new Date(),
                { locale: control.context.locale, displayLongMonth: true }
              )}
            </strong>
          ),
        })
      }}
    />
  )
}
