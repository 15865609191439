import { useEffect, useRef } from 'react'
import { useRouter } from 'next/router'

import { triggerPageView } from 'bl-utils/src/analytics/events'

export const useGlobalPageViewTrigger = (
  locale: 'is' | 'en',
  currencyCode: string
) => {
  const refLastSubpage = useRef<string | null>(null)
  const router = useRouter()

  // Effect that triggers Google Analytics page event
  useEffect(() => {
    const pathSplit = window?.location?.pathname?.split('/')
    if (currencyCode && pathSplit) {
      const pageSubCategory = pathSplit[pathSplit.length - 1]

      if (pageSubCategory !== refLastSubpage.current) {
        refLastSubpage.current = pageSubCategory
        triggerPageView({
          language: locale,
          currencyCode,
          pageSubCategory,
        })
      }
    }
  }, [locale, router.asPath, currencyCode])
}
