import { useEffect } from 'react'

export const ErrorFocus = ({ isSubmitting, isValidating, errors }) => {
  useEffect(() => {
    const keys = Object.keys(errors)
    if (keys.length > 0 && isSubmitting && !isValidating) {
      const errorElement: any = document.querySelector('[aria-invalid="true"]')
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
        errorElement.focus({ preventScroll: true })
      }
    }
  }, [isSubmitting, isValidating, errors])

  return null
}
