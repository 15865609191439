import { defineMessages } from 'react-intl'

const info = defineMessages({
  breadcrumbTitle: {
    id: 'spaBooking:extras.breadcrumbs.title',
    defaultMessage: 'Extras',
    description: 'Extras: Title of extras step breadcrumb',
  },
  title: {
    id: 'spaBooking:extras.title',
    defaultMessage: 'Enhance your stay',
    description: 'Extras: Title of extras step',
  },
  subtitle: {
    id: 'spaBooking:extras.subtitle',
    defaultMessage:
      'Make the most of your visit. You may like to add the following.',
    description: 'Extras: Subtitle of extras step',
  },
  cardPriceFromLabel: {
    id: 'spaBooking:extras.card.common.priceFrom',
    defaultMessage: 'From %p for {extraDurationInMinutes} minutes',
    description: 'Extras: From X for Y minutes',
  },
  massageCardTitle: {
    id: 'spaBooking:extras.card.massage.title',
    defaultMessage: 'In-water massage',
    description: 'Extras: Card title for the massage extra card',
  },
  massageCardSubtitle: {
    id: 'spaBooking:extras.card.massage.subtitle',
    defaultMessage: 'Relaxing. Euphoric. Hypnotic.',
    description: 'Extras: Card subtitle for the massage extra card',
  },
  floatCardTitle: {
    id: 'spaBooking:extras.card.float.title',
    defaultMessage: 'Float therapy',
    description: 'Extras: Card title for the float extra card',
  },
  floatCardSubtitle: {
    id: 'spaBooking:extras.card.float.subtitle',
    defaultMessage:
      '45 hypnotic minutes of warmth, weightlessness, and gentle bodywork.',
    description: 'Extras: Card subtitle for the float extra card',
  },
  lavaCardTitle: {
    id: 'spaBooking:extras.card.lava.title',
    defaultMessage: 'Would you like to dine at Lava Restaurant?',
    description: 'Extras: Card title for the lava extra card',
  },
  lavaCardSubtitle: {
    id: 'spaBooking:extras.card.lava.subtitle',
    defaultMessage: 'Dine overlooking The Blue Lagoon.',
    description: 'Extras: Card subtitle for the lava extra card',
  },
  continueButton: {
    id: 'spaBooking:extras.continue',
    defaultMessage: 'Continue', // TODO use global continue
    description: 'Extras: Continue',
  },
})

const warnings = defineMessages({})

const errors = defineMessages({
  missingDate: {
    id: 'spaBooking:extras.errors.missingDate',
    defaultMessage: 'No date selected.',
    description: 'Extras: Error message when no date is selected',
  },
})

export const extrasMessages = {
  info,
  warnings,
  errors,
}
