import { globalBookingMessages } from '../messages'

export const selectDayVisitTimeFieldMessages = {
  timeSlotsTimeLabel: globalBookingMessages.text.timeSlotsTimeLabel,
  timeFullyBookedError: globalBookingMessages.errors.timeFullyBookedError,
  availabilityFetchError: globalBookingMessages.errors.availabilityFetchError,
  timeRefetch: globalBookingMessages.buttons.timeRefetch,
  timeSlotsPriceRetreatLabel:
    globalBookingMessages.text.timeSlotsPriceRetreatLabel,
  timeSlotsPriceLabel: globalBookingMessages.text.timeSlotsPriceLabel,
  chooseTime: globalBookingMessages.labels.chooseTime,
  selected: globalBookingMessages.labels.selected,
  timeSlotsAvailableSlotsLeft:
    globalBookingMessages.text.timeSlotsAvailableSlotsLeft,
  select: globalBookingMessages.buttons.select,
  timeSlotsFullyBooked: globalBookingMessages.text.timeSlotsFullyBooked,
  timeSlotsOtherOptions: globalBookingMessages.text.timeSlotsOtherOptions,
  noAvailability: globalBookingMessages.errors.noAvailability,
}
