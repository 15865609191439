import { handleGraphQLErrors } from './errors'

interface ErrorObject {
  message: string
  code?: string
}

export function call<T>(
  promise: Promise<any>
): Promise<[null, ErrorObject] | [T, null, any]> {
  return new Promise(resolve => {
    promise
      .then(response => {
        if (response.errors && response.errors.length > 0) {
          return resolve([null, response.errors[0], response])
        }

        if (!response.data) {
          // TOOD: localise and sync with codes when they are implemented
          return resolve([null, { message: 'No response', code: '0' }])
        }
        return resolve([response.data, null, response])
      })
      .catch(error => {
        if (error.graphQLErrors && error.graphQLErrors.length > 0) {
          return resolve([null, error.graphQLErrors[0]])
        }

        resolve([null, error])
      })
  })
}

export function callAndResolve<T>(promise: Promise<any>): Promise<T> {
  return new Promise((resolve, reject) => {
    promise
      .then(response => {
        if (response.errors && response.errors.length > 0) {
          return reject(response.errors[0])
        }
        return resolve(response.data)
      })
      .catch(error => {
        reject(handleGraphQLErrors(error))
      })
  })
}
