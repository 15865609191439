import { useCallback, useContext, useEffect } from 'react'
import { initialInputStyle } from 'payment/src/SecureFields/config'
import { SecureFieldsForm } from 'payment/src/SecureFields/SecureFieldsForm'
import { SecureFieldsSuccessData } from 'payment/src/SecureFields/types'
import { Iframe3dsData } from 'payment/src/SecureFields/types'
import { useTheme } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { CurrencyContext } from 'bl-common/src/context/Currency/CurrencyProvider'
import { themeToPresetsMap } from 'bl-common/src/elements/ProgressButton/ProgressButton'
import { Spinner } from 'bl-common/src/elements/Spinner'
import { SpinnerWrapper } from 'bl-common/src/elements/SpinnerWrapper'
import { useCartContext } from 'bl-common/src/hooks/useCartContext'
import { useMailListSignup } from 'bl-common/src/hooks/useMailListSignup'
import { ScreenTheme } from 'bl-common/src/styles/types'
import {
  buildButton,
  buildCustomField,
  buildDisclaimerField,
  buildText,
  FlowControl,
} from 'bl-flows-core'
import { Cart, CartType, CreatePaymentTransactionMutation } from 'bl-graphql'
import { triggerEvent } from 'bl-utils/src/analytics/events'
import { calcPrice } from 'bl-utils/src/currency/calcPrice'
import { formatPrice } from 'bl-utils/src/currency/formatPrice'
import { sentryLogging } from 'bl-utils/src/sentryUtils'

import { orderAdmissions } from '../../../flows/spa/utils/ordered-admissions'
import { globalBookingMessages } from '../../../messages'
import { getSpaBookingItems } from '../../../utils/get-analytics-items'
import { paymentDetailsMessages } from '../messages/paymentDetails'
import { completeBookingAndNavigate, ConfirmationPaths } from '../utils'
import { CheckoutProgressComponent } from './CheckoutProgress'

type PaymentStatus =
  | 'paymentInitializing'
  | 'transactionInitialized'
  | 'paymentInitialized'
  | 'paymentProcessing'
  | 'paymentError'
  | 'paymentInitializingError'
  | 'payment3DSChallenge'
  | 'checkoutProcessIsRunning'
  | 'checkoutProcessFinished'

interface PaymentState {
  paymentStatus: PaymentStatus
  redirectUrl?: string
  hasPaymentError?: boolean
  transactionDetails?: CreatePaymentTransactionMutation['createPaymentTransaction']
  cardInfo?: SecureFieldsSuccessData['cardInfo']
  initallyDetectedPaymentMethod?: string
}

type UsePaymentOptions = {
  control: FlowControl
  cartType: CartType
  finalizePaymentCallback: () => Promise<void> | void
  paymentSuccessCallback?: () => Promise<void> | void
  confirmationPaths: ConfirmationPaths
  newsletterId?: string
  buttonLabel?: string
}

const triggerPaymentInfoEvent = control => {
  const cart = control.flow.setupHook?.cart as Cart
  const price = cart?.paymentAmount ?? cart?.totalAmount
  const exchangeRates = control.flow.setupHook?.exchangeRates
  const orderedAdmissions = orderAdmissions(cart)
  const analyticsItems = getSpaBookingItems(
    orderedAdmissions,
    exchangeRates,
    cart?.promoCode ?? ''
  )

  triggerEvent({
    event: 'add_payment_info',
    ecommerce: {
      currency: 'EUR',
      value: price ? calcPrice(price, exchangeRates?.EUR) : 0,
      coupon: cart?.promoCode ?? '',
      payment_type: 'Credit Card',
      items: analyticsItems,
    },
  })
}

export const usePayment = ({
  control,
  finalizePaymentCallback,
  confirmationPaths,
  newsletterId,
  cartType,
  paymentSuccessCallback,
}: UsePaymentOptions) => {
  const { currency, retrievalReferenceNumber } = useContext(CurrencyContext)
  const { signUp } = useMailListSignup({
    klaviyoListId: newsletterId,
  })
  const setPaymentState = (newState: PaymentState) => {
    control.screen.setState({
      ...newState,
      hasPaymentError: newState.paymentStatus === 'paymentError',
    })
  }

  const {
    createPaymentTransaction,
    getCheckoutStatus,
    cartId,
    checkoutStatusData,
    hasCheckoutPollingError,
  } = useCartContext()

  const initializePaymentTransaction = async () => {
    const returnUrl = `${window.location.origin}/api/handle3DSecure`
    const response = await createPaymentTransaction({
      returnUrl,
      type: cartType,
      cartId,
      ...(control.context.useMCPPayment &&
        currency !== 'ISK' &&
        retrievalReferenceNumber && {
          mcp: {
            currency,
            retrievalReferenceNumber,
          },
        }),
    })
    return response?.createPaymentTransaction
  }

  const createTransaction = useCallback(async () => {
    try {
      setPaymentState({
        paymentStatus: 'paymentInitializing',
      })

      const transactionDetails = await initializePaymentTransaction()

      setPaymentState({
        paymentStatus: 'transactionInitialized',
        transactionDetails,
      })

      return transactionDetails
    } catch (err) {
      if (err?.message?.includes('1043')) {
        sentryLogging({
          message: 'Checkout process was finished',
          extras: { cartId },
        })
        setPaymentState({
          paymentStatus: 'checkoutProcessFinished',
        })
      } else if (err?.message?.includes('1042')) {
        sentryLogging({
          message: 'Checkout process is running',
          extras: { cartId },
        })
        setPaymentState({
          paymentStatus: 'checkoutProcessIsRunning',
        })
        getCheckoutStatus()
      } else {
        setPaymentState({
          paymentStatus: 'paymentInitializingError',
        })
      }
    }
  }, [cartId])

  const finalizePayment = useCallback(async () => {
    try {
      setPaymentState({
        paymentStatus: 'paymentProcessing',
      })
      await finalizePaymentCallback()
    } catch (err) {
      console.error(err)
    }
  }, [control.screen.state])

  const handlePaymentError = async () => {
    await control.confirm(
      control.context.t(globalBookingMessages.errors.paymentDefaultMessage),
      {
        title: control.context.t(
          globalBookingMessages.errors.paymentDefaultTitle
        ),
        confirmLabel: control.context.t(
          globalBookingMessages.errors.paymentDefaultButtonLabel
        ),
      }
    )
    createTransaction()
  }

  // Set the checkout status in the UI state
  useEffect(() => {
    control.screen.setUiState({
      checkoutStatus: checkoutStatusData?.getCheckoutStatus,
    })
  }, [checkoutStatusData?.getCheckoutStatus])

  const pollingError = checkoutStatusData?.getCheckoutStatus?.error

  // Handle checkout status error
  useEffect(() => {
    if (hasCheckoutPollingError) {
      // We only want to log when the payment was successful but the checkout failed
      if (checkoutStatusData?.getCheckoutStatus?.paymentSuccessful) {
        sentryLogging({
          message: `Failed to finalize booking: ${pollingError}`,
          extras: {
            cartType,
            bookingNumber: checkoutStatusData?.getCheckoutStatus?.bookingRef,
          },
        })
      }

      setPaymentState({
        paymentStatus: 'paymentError',
        redirectUrl: undefined,
      })
    }
  }, [hasCheckoutPollingError])

  // Handle checkout status success
  const bookingNumber = checkoutStatusData?.getCheckoutStatus?.bookingRef
  const finishedBooking = checkoutStatusData?.getCheckoutStatus?.finished
  useEffect(() => {
    if (bookingNumber && finishedBooking) {
      control.screen.setState({
        bookingNumber,
      })

      completeBookingAndNavigate({
        control,
        confirmationPaths,
        bookingNumber,
        newsletterSignup: signUp,
        paymentSuccessCallback,
      })
    }
  }, [bookingNumber, finishedBooking])

  useEffect(() => {
    if (control.screen?.state?.hasPaymentError) {
      handlePaymentError()
    }
  }, [control.screen?.state?.hasPaymentError])

  useEffect(() => {
    if (cartId) {
      createTransaction()
    }
  }, [cartId])

  return {
    createTransaction,
    finalizePayment,
    setPaymentState,
    getCheckoutStatus,
    currency,
  }
}

const handleCallback = (
  control: FlowControl,
  {
    status,
    data,
  }: {
    status: 'success' | 'failure' | 'error'
    data?: SecureFieldsSuccessData | Iframe3dsData
  }
) => {
  const setPaymentState = control.screen.setupHook?.setPaymentState
  if (status === 'success') {
    if ('redirect' in data) {
      setPaymentState({
        paymentStatus: 'payment3DSChallenge',
        redirectUrl: data.redirect,
        cardInfo: data.cardInfo,
      })
    } else {
      if ('cardInfo' in data) {
        setPaymentState({
          cardInfo: data.cardInfo,
        })
      }
      control.screen.setupHook?.finalizePayment?.()
    }
  } else if (status === 'error' || status === 'failure') {
    setPaymentState({ paymentStatus: 'paymentError' })
    control.screen.setupHook?.createTransaction?.()
  }
}

export const paymentDetailFields = (
  screenTheme?: ScreenTheme,
  triggerPaymentInfoEventCallback?: (control: FlowControl) => void
) => {
  return [
    buildCustomField({
      defaultValue: null,
      condition: control =>
        control.screen?.state?.paymentStatus === 'paymentProcessing',
      props: {
        render: control => {
          const checkoutStatus = control.screen.uiState?.checkoutStatus
          return (
            <CheckoutProgressComponent
              checkoutStatus={checkoutStatus}
              screenTheme={screenTheme}
              translations={{
                payment: control.context.t(
                  globalBookingMessages.text.paymentStatus
                ),
                order: control.context.t(
                  globalBookingMessages.text.orderStatus
                ),
                confirm: control.context.t(
                  globalBookingMessages.text.confirmStatus
                ),
              }}
            />
          )
        },
      },
      layout: {
        spacing: {
          mt: { xs: 2 },
        },
      },
    }),
    buildCustomField({
      defaultValue: null,
      condition: control => {
        return (
          control.screen?.state?.paymentStatus !== 'paymentError' &&
          control.screen?.state?.paymentStatus !== 'paymentInitializingError' &&
          control.screen?.state?.paymentStatus !== 'checkoutProcessFinished' &&
          control.screen?.state?.paymentStatus !== 'paymentProcessing'
        )
      },
      props: {
        render: control => {
          const theme = useTheme()
          const paymentStatus = control.screen.state?.paymentStatus
          const currency = control.screen.setupHook?.currency
          const iskAmount = formatPrice(
            calcPrice(control.flow.setupHook?.cart?.paymentAmount || 0),
            'ISK',
            false
          )

          // Only VISA and Mastercard are supported for MCP
          // We only make this check when we know the payment method
          const isMCPSupported = control.screen?.state?.paymentMethod
            ? ['VIS', 'ECA'].includes(control.screen.state.paymentMethod)
            : true

          const showDisclaimer = Boolean(
            control.context.useMCPPayment &&
              control.screen.setupHook?.currency !== 'ISK' &&
              !isMCPSupported
          )

          const disclaimerText = showDisclaimer
            ? control.context.t(globalBookingMessages.text.MCPNotSupported, {
                currency,
                iskAmount,
              })
            : null

          const buttonLabel = control.context.t(
            paymentDetailsMessages.info.buttonContinue,
            {
              iskPrice: showDisclaimer
                ? iskAmount
                : control.flow.setupHook?.formattedPrice,
            }
          )

          if (!paymentStatus || paymentStatus === 'paymentInitializing') {
            return (
              <SpinnerWrapper>
                <Spinner
                  shouldAnimate
                  color={theme?.bookingEngine?.[screenTheme]?.spinner?.color}
                />
              </SpinnerWrapper>
            )
          }

          return (
            <SecureFieldsForm
              transactionId={
                control.screen.state?.transactionDetails?.transactionId
              }
              formStrings={{
                cardNumber: {
                  label: control.context.t(
                    globalBookingMessages.labels.cardNumber
                  ),
                  placeholder: control.context.t(
                    globalBookingMessages.placeholders.cardNumber
                  ),
                  required: control.context.t(
                    globalBookingMessages.errors.cardRequired
                  ),
                  invalid: control.context.t(
                    globalBookingMessages.errors.cardInvalid
                  ),
                  notSupported: control.context.t(
                    globalBookingMessages.errors.cardNotSupported
                  ),
                },
                cvv: {
                  label: control.context.t(globalBookingMessages.labels.cvc),
                  placeholder: control.context.t(
                    globalBookingMessages.placeholders.cvc
                  ),
                  required: control.context.t(
                    globalBookingMessages.errors.cvcRequired
                  ),
                  invalid: control.context.t(
                    globalBookingMessages.errors.cvcInvalid
                  ),
                },
                expiryDate: {
                  label: control.context.t(
                    globalBookingMessages.labels.expirationDate
                  ),
                  placeholder: control.context.t(
                    globalBookingMessages.placeholders.expirationDate
                  ),
                  required: control.context.t(
                    globalBookingMessages.errors.expirationRequired
                  ),
                  invalid: control.context.t(
                    globalBookingMessages.errors.expirationInvalid
                  ),
                },
                buttonLabel,
              }}
              callbacks={{
                onSuccess: data => {
                  if (control.flow.setupHook?.isSPA) {
                    if (triggerPaymentInfoEventCallback) {
                      triggerPaymentInfoEventCallback(control)
                    } else {
                      triggerPaymentInfoEvent(control)
                    }
                  }

                  handleCallback(control, { data, status: 'success' })
                },
                onChange: data => {
                  control.screen.setupHook?.setPaymentState({
                    paymentMethod: data.fields?.cardNumber?.paymentMethod,
                  })
                },
                onReady: () => {
                  control.screen.setupHook?.setPaymentState({
                    paymentStatus: 'paymentInitialized',
                  })
                },
                onError: error => {
                  if (error === 'initializationError') {
                    control.screen.setupHook?.setPaymentState({
                      paymentStatus: 'paymentInitializingError',
                    })
                  } else {
                    handleCallback(control, { status: 'error' })
                  }
                },
              }}
              options={
                screenTheme === 'retreat'
                  ? {
                      styles: {
                        cardNumber: initialInputStyle(colors.white),
                        cvv: initialInputStyle(colors.white),
                        '*::placeholder': `opacity: 0.5; color: ${colors.white}`,
                      },
                    }
                  : undefined
              }
              themeStyles={{
                input:
                  theme?.bookingEngine?.[screenTheme]?.inputTextField?.input,
                button: {
                  style:
                    theme?.bookingEngine?.[screenTheme]?.buttonField?.button,
                  preset: themeToPresetsMap[screenTheme],
                },
              }}
              on3dsStatusChange={data =>
                handleCallback(control, { data, status: data.status })
              }
              disclaimer={{
                text: disclaimerText,
                color: colors.orange,
              }}
            />
          )
        },
      },
    }),
    buildText({
      condition: control =>
        Boolean(control.screen.state?.transactionDetails?.transactionId) &&
        Boolean(control.screen.state?.paymentStatus === 'paymentInitialized'),
      props: {
        type: 'text',
        value: control =>
          control.context.t(
            globalBookingMessages.labels.requiredAsterikExplanation
          ),
      },
      layout: {
        spacing: {
          mb: { xs: 5, md: 4, bmd: 0 },
        },
      },
    }),
    // Let the user know that the checkout process is finished
    buildDisclaimerField({
      id: 'checkoutAlreadyProcessed',
      props: {
        value: control =>
          control.context.t(
            globalBookingMessages.warnings.checkoutAlreadyProcessed
          ),
        preset: 'label',

        color: colors.lagoonBlue,
        showDisclaimer: control =>
          Boolean(
            control.screen?.state?.paymentStatus === 'checkoutProcessFinished'
          ),
      },
      layout: {
        spacing: { mb: { xs: 0.5 } },
      },
    }),
    buildButton({
      condition: control =>
        control.screen?.state?.paymentStatus === 'checkoutProcessFinished',
      props: {
        label: control =>
          control.context.t(globalBookingMessages.buttons.continue),
        onClick: control => control.screen.setupHook?.getCheckoutStatus?.(),
      },
      layout: {
        spacing: {
          mt: { xs: 2 },
        },
      },
    }),
    // Reinitalize payment if there is an initialization error
    buildDisclaimerField({
      id: 'paymentInitializationError',
      props: {
        value: control =>
          control.context.t(
            globalBookingMessages.errors.paymentInitializedError
          ),
        preset: 'label',
        color: colors.deepRed,
        showDisclaimer: control =>
          Boolean(
            control.screen?.state?.paymentStatus === 'paymentInitializingError'
          ),
      },
      layout: {
        spacing: { mb: { xs: 0.5 } },
      },
    }),
    buildButton({
      condition: control =>
        control.screen?.state?.paymentStatus === 'paymentInitializingError' ||
        (control.screen?.state?.paymentStatus === 'paymentInitialized' &&
          Boolean(!control.screen?.state?.transactionDetails?.transactionId)),
      props: {
        label: 'Retry',
        onClick: control => control.screen.setupHook?.createTransaction?.(),
      },
      layout: {
        spacing: {
          mt: { xs: 2 },
        },
      },
    }),
  ]
}
