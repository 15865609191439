import { defineMessages } from 'react-intl'

const info = defineMessages({
  breadcrumbTitle: {
    id: 'spaBooking:extrasTransportation.breadcrumbs.title',
    defaultMessage: 'Extras',
    description: 'Extras - transportation: Title of extras step breadcrumb',
  },
  title: {
    id: 'spaBooking:extrasTransportation.title',
    defaultMessage: 'Would you like to reserve your transportation?',
    description: 'Extras - transportation: Title of extras transportation step',
  },
  subtitle: {
    id: 'spaBooking:extrasTransportation.subtitle',
    defaultMessage:
      'Offering hotel pickups, complimentary wi-fi, and a luxurious fleet of coaches, Destination Blue Lagoon enables you to relax and enjoy the journey to a wonder of the world. Children travel free. Pickups start 30 minutes before departure.',
    description:
      'Extras - transportation: Subtitle of extras transportation step',
  },
  tooCloseToArrivalForTransport: {
    id: 'spaBooking:extrasTransportation.tooCloseToArrivalForTransport',
    defaultMessage:
      'Unfortunately it is too late to add bus transfer to this booking.',
    description:
      'Extras - transportation: Disclaimer when it is too late to add bus transfer',
  },
  addTransportationModal: {
    id: 'spaBooking:extrasTransportation.button.addTransportation',
    defaultMessage: 'Add transportation (from {formattedPrice})',
    description: 'Extras - transportation: Add button label',
  },
  ChangeBusBookingTitle: {
    id: 'spaBooking:extrasTransportation.ChangeBusBookingTitle.',
    defaultMessage: 'Change bus booking',
    description: 'Extras - transportation: Change bus booking title',
  },
  ChangeBusBookingSubtitle: {
    id: 'spaBooking:extrasTransportation.ChangeBusBookingSubtitle.',
    defaultMessage:
      'You already have a bus transfer in your cart. If you start a new bus booking your current booking will be removed from cart.',
    description: 'Extras - transportation: Change bus booking subtitle',
  },
  RemoveFromCartButton: {
    id: 'spaBooking:extrasTransportation.RemoveFromCartButton.',
    defaultMessage: 'Remove from cart',
    description: 'Extras - transportation: Remove from cart button',
  },
})

const warnings = defineMessages({})

const errors = defineMessages({
  missingDate: {
    id: 'spaBooking:extrasTransportation.errors.missingDate',
    defaultMessage: 'No date selected.',
    description:
      'Extras - transportation: Error message when no date is selected',
  },
})

export const extrasTransportationMessages = {
  info,
  warnings,
  errors,
}
