import { useState } from 'react'
import styled from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { Spinner } from 'bl-common/src/elements/Spinner'
import { SpinnerWrapper } from 'bl-common/src/elements/SpinnerWrapper'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { useCartContext } from 'bl-common/src/hooks/useCartContext'
import { theme } from 'bl-common/src/styles/theme'

const SubscriptionCardAcceptedWrapper = styled.div({
  display: 'flex',
  padding: `0 ${theme.spacing[1]}`,
  justifyContent: 'space-between',
  alignItems: 'center',
  border: '1px solid #169222',
  color: colors.midGrey,
  background: '#eaf4e9',
  marginBottom: theme.spacing[1.5],
  marginTop: theme.spacing[-1],
})

const LeftContent = styled.div({
  display: 'flex',
  alignItems: 'center',
})

const RightContent = styled.div({
  display: 'flex',
  alignItems: 'center',
  minHeight: 60,
})

const RemoveButton = styled.button({
  background: 'transparent',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  paddingY: theme.spacing[1],
})

const Check = () => (
  <svg
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    height="18px"
    width="18px"
    style={{ marginRight: '14px' }}
  >
    <path
      d="M4.77637 7.49275L8.92562 12.5077L16.2092 3.01514"
      stroke="#454647"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M16.806 7.31343C16.9254 7.85075 16.9851 8.41791 16.9851 8.98507C16.9851 13.403 13.403 16.9851 8.98507 16.9851C4.56716 16.9851 0.985075 13.403 0.985075 8.98507C0.985075 4.56716 4.56716 0.985075 8.98507 0.985075C10.2388 0.985075 11.4328 1.28358 12.5075 1.79104L13.1045 0.985075C11.8806 0.358209 10.4776 0 8.98507 0C4.02985 0 0 4.02985 0 8.98507C0 13.9403 4.02985 17.9701 8.98507 17.9701C13.9403 17.9701 17.9701 13.9403 17.9701 8.98507C17.9701 8.0597 17.8209 7.19403 17.5821 6.35821L16.806 7.31343Z"
      fill="#454647"
    />
  </svg>
)

const SubscriptionCardAccepted = ({
  acceptedText,
  removeButtonLabel,
}: {
  acceptedText: string
  removeButtonLabel: string
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const {
    removeMembershipToken,
    loading,
    resetCart,
    resetSpaCustomerInfo,
    cart,
  } = useCartContext()
  const onRemove = async () => {
    setIsLoading(true)
    await removeMembershipToken()
    await resetSpaCustomerInfo()
    await resetCart()
  }

  if (!cart.membership?.membershipToken) {
    return null
  }

  return (
    <SubscriptionCardAcceptedWrapper>
      <LeftContent>
        <Check />
        <Type preset="textSmall">{acceptedText}</Type>
      </LeftContent>
      <RightContent>
        {isLoading || loading ? (
          <SpinnerWrapper>
            <Spinner shouldAnimate />
          </SpinnerWrapper>
        ) : (
          <RemoveButton type="button" onClick={onRemove}>
            <Type preset="textSmall">{removeButtonLabel}</Type>
          </RemoveButton>
        )}
      </RightContent>
    </SubscriptionCardAcceptedWrapper>
  )
}

export default SubscriptionCardAccepted
