// Summer cards link should be hidden after 15. sept 2023

import { PRODUCT_IDS } from './ProductIds'

export const MembershipSubType = {
  INDIVIDUAL: 'INDIVIDUAL',
  FAMILY: 'FAMILY',
}

export const MembershipCartType = {
  WINTER_CARD: 'WINTER CARD',
  SUMMER_CARD: 'SUMMER CARD',
  WINTER_FAMILY_CARD: 'WINTER FAM',
  SUMMER_FAMILY_CARD: 'SUMMER FAMILY',
}

export const isNowInDateRange = (from?: string, to?: string) => {
  const fromDate = from ? new Date(from) : new Date()
  const toDate = to ? new Date(to) : new Date()
  const now = new Date()

  if (now >= fromDate && now <= toDate) {
    return true
  }

  return false
}

// Sometimes both summer and winter card are allowed at the same time
// In those cases we want to display info for the card that lasts longer.
export const whichMembershipInfoToShow = ({
  summerStartDateString,
  winterStartDateString,
  isSummerCardAllowed,
  isWinterCardAllowed,
}: {
  summerStartDateString?: string
  winterStartDateString?: string
  isSummerCardAllowed: boolean
  isWinterCardAllowed: boolean
}): 'summer' | 'winter' => {
  if (isSummerCardAllowed && isWinterCardAllowed) {
    const summerStartDate = summerStartDateString
      ? new Date(summerStartDateString)
      : new Date()
    const winterStartDate = winterStartDateString
      ? new Date(winterStartDateString)
      : new Date()

    if (summerStartDate > winterStartDate) {
      return 'summer'
    }

    return 'winter'
  }

  if (isSummerCardAllowed) {
    return 'summer'
  }

  return 'winter'
}

export const getMembershipProductId = (membership: { type?: string }) => {
  const isWinterCard =
    membership?.type === MembershipCartType.WINTER_CARD ||
    membership?.type === MembershipCartType.WINTER_FAMILY_CARD

  return isWinterCard ? PRODUCT_IDS.BLWinterCard : PRODUCT_IDS.BLSummerCard
}
