import { useEffect } from 'react'
import { useAsync } from '@react-hookz/web'

import { IActivityCardFields } from 'bl-common/src/generated/contentful'
import { theme } from 'bl-common/src/styles/theme'
import { ScreenTheme } from 'bl-common/src/styles/types'
import {
  buildButton,
  buildCustomField,
  buildHeading,
  buildScreenWithSidebarLayout,
  FlowControl,
  FlowSidebarLayout,
} from 'bl-flows-core'
import { triggerEvent } from 'bl-utils/src/analytics/events'
import { getTimeFromDateTimeString } from 'bl-utils/src/date'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'

import { isActivityInCart } from '../../../utils/isActivityInCart'
import { Activities } from '../components/Activities'
import { SpaCart } from '../components/SpaCart'
import { extrasMessages } from '../messages/extras'

const cartLayoutProps: FlowSidebarLayout['props'] = {
  id: 'confirm',
}

const triggerFunnelExtrasEvent = (control: FlowControl) => {
  const flowState = control.flow.state
  const massage = isActivityInCart(control, 'massage')
  const float = isActivityInCart(control, 'float')
  const lava = isActivityInCart(control, 'lava')
  const skincare = isActivityInCart(control, 'skincare')
  const spaRestaurant = isActivityInCart(control, 'spaRestaurant')
  const isRetreatSpaFlow = control.flow.setupHook?.isRetreatSpaFlow

  triggerEvent({
    event: 'funnel_extras',
    BLType: isRetreatSpaFlow ? 'Day Visit Spa' : 'Day Visit',
    pageTitle: 'Book Extras',
    pageCategory: 'book',
    Adult: flowState.guests.adults,
    ...(!isRetreatSpaFlow && { Children: flowState.guests.children }),
    Calendar: formatDateInUTC(
      new Date(flowState.calendar.arrivalDate),
      'yyyy-MM-dd'
    ),
    Package: isRetreatSpaFlow
      ? 'retreat spa'
      : control.flow.setupHook?.selectedPackage,
    Time: getTimeFromDateTimeString(flowState?.time?.selectedTime),
    ['In-water massage']: massage ? 'True' : 'False',
    ['Float Therapy']: float ? 'True' : 'False',
    ['Lava Restaurant']: lava ? 'True' : 'False',
    ['Spa Restaurant']: spaRestaurant ? 'True' : 'False',
    ['Skincare']: skincare ? 'True' : 'False',
  })
}

export const extrasScreen = ({ screenTheme }: { screenTheme: ScreenTheme }) => {
  return buildScreenWithSidebarLayout({
    id: 'extras',
    subType: 'form',
    columnStyle: true,
    route: control => {
      const selectedPackage =
        control?.flow?.setupHook?.selectedPackage || 'comfort'
      return {
        en:
          selectedPackage === 'retreat'
            ? 'extras'
            : `${selectedPackage}/extras`,
        is:
          selectedPackage === 'retreat'
            ? 'aukathjonusta'
            : `${selectedPackage}/aukathjonusta`,
      }
    },
    theme: screenTheme,
    layoutProps: cartLayoutProps,
    setupHook: control => {
      const getEntriesWrapper =
        control.context.contentfulClient.getEntriesWrapper

      const [state, actions] = useAsync(() =>
        getEntriesWrapper<IActivityCardFields>({
          content_type: 'activityCard',
          include: 2,
          locale: control.context.locale,
          order: 'fields.order',
        })
      )

      useEffect(() => {
        actions.execute()
      }, [])

      return {
        activities: state?.result?.items,
      }
    },
    breadcrumb: control => ({
      title: control.context.t(extrasMessages.info.breadcrumbTitle),
    }),
    uiState: {
      showMassageFlow: false,
      showFloatFlow: false,
      showLavaFlow: false,
      showSkincareFlow: false,
      showSpaRestaurantFlow: false,
      hasProductAvailabilityLoaded: false,
    },
    fields: {
      main: [
        ...buildHeading({
          title: control => control.context.t(extrasMessages.info.title),
          subTitle: control => control.context.t(extrasMessages.info.subtitle),
          includeBreadcrumb: true,
          showMobileCart: true,
        }),
        buildCustomField({
          defaultValue: null,
          props: {
            component: Activities,
          },
          layout: {
            spacing: {
              mt: { xs: 2, md: 0 },
              mb: { xs: 3 },
            },
          },
        }),
        buildButton({
          props: {
            label: control =>
              control.context.t(extrasMessages.info.continueButton),
            isSubmit: true,
            isDisabled: control =>
              !control.screen.uiStateRef?.current?.productAvailabilityLoaded,
            onClick: control => triggerFunnelExtrasEvent(control),
          },
          layout: {
            marginTopAuto: { xs: true },
            position: { xs: 'sticky' },
            bottom: { xs: 24, bmd: 0 },
            width: { xs: `calc(100% + ${theme.spacing[2]})` },
            spacing: {
              ml: { xs: -1 },
              mr: { xs: -1 },
            },
          },
        }),
      ],
      right: [
        buildCustomField({
          defaultValue: null,
          props: {
            component: SpaCart,
          },
        }),
      ],
    },
  })
}
