import { add } from 'date-fns/add'
import { isAfter } from 'date-fns/isAfter'

const TRANSPORTATION_DEADLINE_HOURS = 3

export const isTooLateToBookTransportation = (date: Date) => {
  const now = new Date()
  const threeHoursFromNow = add(now, { hours: TRANSPORTATION_DEADLINE_HOURS })

  return isAfter(threeHoursFromNow, date)
}
