import { PRODUCT_IDS } from './ProductIds'

export const activityDuration = {
  [PRODUCT_IDS.Massage30]: 30,
  [PRODUCT_IDS.Massage60]: 60,
  [PRODUCT_IDS.Massage120]: 120,
  [PRODUCT_IDS.MassageFloatingOne]: 45,
  [PRODUCT_IDS.MassageFloatingTwo]: 45,
  [PRODUCT_IDS.MassageFloatingMidnight]: 45,
  [PRODUCT_IDS.RestaurantLava]: 120,
}
