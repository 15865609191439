import { defineMessages } from 'react-intl'

const info = defineMessages({
  breadcrumbTitle: {
    id: 'spaBooking:calendar.breadcrumbs.title',
    defaultMessage: 'Date',
    description: 'Calendar: Title of calendar step breadcrumb',
  },
  breadcrumbSubtitle: {
    id: 'spaBooking:calendar.breadcrumbs.subtitle',
    defaultMessage: '{formattedDate}',
    description: 'Calendar: Subtitle of calendar step breadcrumb',
  },
  title: {
    id: 'spaBooking:calendar.title',
    defaultMessage: 'Select a date',
    description: 'Calendar: Title of calendar step',
  },
  subtitle: {
    id: 'spaBooking:calendar.subtitle',
    defaultMessage: 'We are open every day of the year',
    description: 'Calendar: Subtitle of calendar step',
  },
  mobileTitle: {
    id: 'spaBooking:calendar.mobileTitle',
    defaultMessage: 'What date are you visiting?',
    description: 'Calendar: Title of calendar step on mobile for SpaBookingBar',
  },
})

const warnings = defineMessages({})

const errors = defineMessages({
  missingDate: {
    id: 'spaBooking:calendar.errors.missingDate',
    defaultMessage: 'No date selected.',
    description: 'Calendar: Error message when no date is selected',
  },
  invalidDate: {
    id: 'spaBooking:calendar.errors.invalidDate',
    defaultMessage: 'Invalid arrival date',
    description: 'Calendar: Error message when arrival date is invalid',
  },
})

export const calendarMessages = {
  info,
  warnings,
  errors,
}
