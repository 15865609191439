import { CartItem, CartItemType, TransportationItem } from 'bl-graphql'
import { formatDateInUTC } from 'bl-utils/src/formatting/formatDate'
import { HB_PRODUCT_IDS, PRODUCT_IDS } from 'bl-utils/src/ProductIds'
import {
  getProductDurationInMinutes,
  KEFLAVIK_AIRPORT_ID,
} from 'bl-utils/src/productUtils'

import { orderAdmissions } from '../flows/spa/utils/ordered-admissions'
import { globalBookingMessages, productIdsMessages } from '../messages'

const getTransportationDurationInMinutes = (locationId: string) => {
  // 20 minutes from/to Keflavik
  if (locationId === KEFLAVIK_AIRPORT_ID) {
    return 20
  }

  // 60 minutes from/to Reykjavik
  return 60
}

const getItemDescription = (
  item: CartItem,
  t: (message: any, values?: Record<string, any>) => string
) => {
  if (item.type === CartItemType.Admission) {
    switch (item.productId) {
      case HB_PRODUCT_IDS.AdultAdmission:
        return {
          title: t(globalBookingMessages.text.yourHighlandBaseBathsBooking),
          subtitle: t(globalBookingMessages.text.timeSpentHere, {
            hours: 2,
          }),
        }
      case PRODUCT_IDS.SpaRetreat:
        return {
          title: t(globalBookingMessages.text.yourRetreatBooking),
          subtitle: t(globalBookingMessages.text.timeSpentHere, {
            hours: 5,
          }),
        }
      default:
        return {
          title: t(globalBookingMessages.text.yourBluelagoonBooking),
          subtitle: t(globalBookingMessages.text.timeSpentHere, {
            hours: 3,
          }),
        }
    }
  }

  if (item.type === CartItemType.Transfer) {
    const direction = (item as any).meta?.direction || ''
    if (direction === 'pickup') {
      return {
        title: t(globalBookingMessages.labels.pickup),
        subtitle: t(globalBookingMessages.text.timeMinutes, {
          minutes: getTransportationDurationInMinutes(
            (item as TransportationItem).meta.inboundPickupLocationId
          ),
        }),
      }
    }

    if (direction === 'dropoff') {
      return {
        title: t(globalBookingMessages.labels.dropoff),
        subtitle: t(globalBookingMessages.text.timeMinutes, {
          minutes: getTransportationDurationInMinutes(
            (item as TransportationItem).meta.outboundDropoffLocationId
          ),
        }),
      }
    }
  }

  switch (item.productId) {
    case PRODUCT_IDS.RestaurantLava:
      return {
        title: t(productIdsMessages['LAVA-RES']),
        subtitle: t(globalBookingMessages.text.timeSpentHere, {
          hours: getProductDurationInMinutes(PRODUCT_IDS.RestaurantLava),
        }),
      }
    case PRODUCT_IDS.SpaRestaurant:
      return {
        title: t(productIdsMessages['SPA-RES']),
        subtitle: t(globalBookingMessages.text.timeSpentHere, {
          hours: getProductDurationInMinutes(PRODUCT_IDS.SpaRestaurant),
        }),
      }
    case HB_PRODUCT_IDS.HighlandBaseRestaurant:
      return {
        title: t(productIdsMessages[HB_PRODUCT_IDS.HighlandBaseRestaurant]),
        subtitle: t(globalBookingMessages.text.timeSpentHere, {
          hours: getProductDurationInMinutes(
            HB_PRODUCT_IDS.HighlandBaseRestaurant
          ),
        }),
      }
    default:
      return {
        title: t(productIdsMessages[item.productId]),
        subtitle: t(globalBookingMessages.text.timeMinutes, {
          minutes: getProductDurationInMinutes(item.productId),
        }),
      }
  }
}

export const getSpaCartItinerary = (
  items: CartItem[],
  t: (message: any, values?: Record<string, any>) => string
) => {
  const orderedItems = orderAdmissions({
    items,
  }).filter(item => item?.type !== CartItemType.Item) // products shouldn't be shown in the itinerary

  return orderedItems.map(item => {
    const date = item.meta?.arrivalTime
      ? new Date(item.meta.arrivalTime)
      : new Date() // Default to new Date() if no arrivalTime is set

    return {
      time: formatDateInUTC(date, 'HH:mm'),
      date: formatDateInUTC(date, 'dd MMM'),
      productNo: item.productId,
      ...getItemDescription(item, t),
    }
  })
}
