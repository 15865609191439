import { defineMessages } from 'react-intl'

const info = defineMessages({
  breadcrumbTitle: {
    id: 'spaBooking:paymentDetails.breadcrumbs.title',
    defaultMessage: 'Checkout',
    description: 'Payment Details: Title of payment details step breadcrumb',
  },
  title: {
    id: 'spaBooking:paymentDetails.title',
    defaultMessage: 'payment details',
    description: 'Payment Details: Title of payment details step',
  },
  subtitle: {
    id: 'spaBooking:paymentDetails.subtitle',
    defaultMessage:
      'Total for your payment on *{formattedDate}:* {formattedPrice}',
    description: 'Payment Details: Subtitle of payment details step',
  },
  labelCardNumber: {
    id: 'spaBooking:paymentDetails.label.cardNumber',
    defaultMessage: 'Card number*',
    description: 'Payment Details: Label for card number',
  },
  placeholderCardNumber: {
    id: 'spaBooking:paymentDetails.placeholder.cardNumber',
    defaultMessage: '**** **** **** ****',
    description: 'Payment Details: Placeholder for card number',
  },
  labelExpirationDate: {
    id: 'spaBooking:paymentDetails.label.expirationDate',
    defaultMessage: 'Expiration date*',
    description: 'Payment Details: Label for expiration date',
  },
  placeholderExpirationDate: {
    id: 'spaBooking:paymentDetails.placeholder.expirationDate',
    defaultMessage: 'Expiration date*',
    description: 'Payment Details: Placeholder for expiration date',
  },
  labelCVC: {
    id: 'spaBooking:paymentDetails.label.cvc',
    defaultMessage: 'CVC*',
    description: 'Payment Details: Label for CVC',
  },
  placeholderCVC: {
    id: 'spaBooking:paymentDetails.placeholder.cvc',
    defaultMessage: '***',
    description: 'Payment Details: Placeholder for CVC',
  },
  buttonContinue: {
    id: 'spaBooking:paymentDetails.button.continue',
    defaultMessage: 'Complete payment ({iskPrice})',
    description: 'Payment Details: Complete payment',
  },
})

const warnings = defineMessages({})

const errors = defineMessages({})

export const paymentDetailsMessages = {
  info,
  warnings,
  errors,
}
