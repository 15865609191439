import { colors } from 'bl-common/src/constants/colors'
import { ScreenTheme } from 'bl-common/src/styles/types'
import {
  buildCustomField,
  buildHeading,
  buildScreenWithSidebarLayout,
} from 'bl-flows-core'
import { CartType } from 'bl-graphql'
import { displayDate } from 'bl-utils/src/formatting/formatDate'

import {
  paymentDetailFields,
  usePayment,
} from '../components/PaymentDetailsFields'
import { SpaCart } from '../components/SpaCart'
import { paymentDetailsMessages } from '../messages/paymentDetails'
import {
  ConfirmationPaths,
  finalizeBooking,
  getSpaFlowSettingsByAdmissionType,
  getSpaNewsletterId,
} from '../utils'

export const paymentDetailsScreen = ({
  screenTheme,
  confirmationPaths,
}: {
  screenTheme: ScreenTheme
  confirmationPaths: ConfirmationPaths
}) => {
  return buildScreenWithSidebarLayout({
    id: 'payment',
    subType: 'section',
    route: control => {
      const selectedPackage =
        control?.flow?.setupHook?.selectedPackage || 'comfort'
      return {
        en:
          selectedPackage === 'retreat'
            ? 'payment'
            : `${selectedPackage}/payment`,
        is:
          selectedPackage === 'retreat'
            ? 'greidsla'
            : `${selectedPackage}/greidsla`,
      }
    },
    layoutProps: {
      id: 'confirm',
    },
    theme: screenTheme,
    breadcrumb: control => ({
      title: control.context.t(paymentDetailsMessages.info.breadcrumbTitle),
    }),
    setupHook: control => {
      // Get the selected package
      const selectedPackage =
        control?.flow?.setupHook?.selectedPackage || 'comfort'

      // Get the locale
      const locale = control.context.locale

      // Get spa flow settings for the admission type
      const spaFlowSettings = getSpaFlowSettingsByAdmissionType(
        selectedPackage,
        control.context.spaFlowSettings
      )

      // Get the Klaviyo newsletter id and fallback to the english one if the icelandic one is not set
      const newsletterId = getSpaNewsletterId(spaFlowSettings, locale)

      return {
        hideCurrencyChangeInCart: true,
        ...usePayment({
          control,
          cartType: CartType.Dayspa,
          finalizePaymentCallback: () => finalizeBooking(control),
          confirmationPaths,
          newsletterId,
        }),
      }
    },
    fields: {
      main: [
        ...buildHeading({
          title: control =>
            control.context.t(paymentDetailsMessages.info.title),
          subTitle: control => {
            const localeDate = displayDate(
              control.flow.state.calendar?.arrivalDate,
              {
                locale: control.context.locale,
              }
            )
            // for icelandic, we need to remove the dot at the end of the date e.g. '5. des.' => '5. des'
            const formattedDate =
              control.context.locale === 'is' && localeDate?.endsWith('.')
                ? localeDate?.slice(0, -1)
                : localeDate

            return control.context.t(paymentDetailsMessages.info.subtitle, {
              formattedDate,
              formattedPrice: `<span style="color: ${
                screenTheme === 'retreat' ? colors.blueOnDark : colors.deepBlue
              }; font-weight: bold;">${
                control.flow.setupHook?.formattedPrice
              }</span>`,
            })
          },
          includeBreadcrumb: true,
          showMobileCart: true,
        }),
        ...paymentDetailFields(screenTheme),
      ],
      right: [
        buildCustomField({
          defaultValue: null,
          props: {
            component: SpaCart,
          },
        }),
      ],
    },
  })
}
