import { defineMessages } from 'react-intl'

const info = defineMessages({
  breadcrumbTitle: {
    id: 'spaBooking:guests.breadcrumbs.title',
    defaultMessage: 'Guests',
    description: 'Guests: Title of guest step breadcrumb',
  },
  breadcrumbSubtitle: {
    id: 'spaBooking:guests.breadcrumbs.subtitle',
    defaultMessage: '{guests}x {guests, plural, one {guest} other {guests}}',
    description: 'Guests: Subtitle of guest step breadcrumb',
  },
  title: {
    id: 'spaBooking:guests.title',
    defaultMessage: 'How many of you are visiting?',
    description: 'Guests: Title of guest step',
  },
  titleRetreat: {
    id: 'spaBooking:guests.titleRetreat',
    defaultMessage: 'How many of you are visiting?',
    description: 'Guests: Title of guest step for Retreat Spa',
  },
  subtitle: {
    id: 'spaBooking:guests.subtitle',
    defaultMessage:
      'Prices vary depending on time of day. Children 13 and under enjoy free admission.*',
    description: 'Guests: Subtitle of guest step',
  },
  subtitleMembership: {
    id: 'spaBooking:guests.subtitleMembership',
    defaultMessage: 'Children 13 and under enjoy free admission.*',
    description: 'Guests: Subtitle of guest step for membership',
  },
  subtitleRetreat: {
    id: 'spaBooking:guests.subtitleRetreat',
    defaultMessage:
      'Prices vary depending on time of day. Children 13 and under enjoy free admission.*',
    description: 'Guests: Subtitle of guest step for Retreat Spa',
  },
  adultPickerLabel: {
    id: 'spaBooking:guests.adultPickerLabel',
    defaultMessage: 'Ages 14+',
    description:
      'Guests: Label for selecting how many adults guests will be in the booking',
  },
  adultRetreatPickerLabel: {
    id: 'spaBooking:guests.adultRetreatPickerLabel',
    defaultMessage: 'Ages 12+',
    description:
      'Guests: Label for selecting how many adult guests will be in a Retreat booking',
  },
  changingRoomsPickerLabel: {
    id: 'spaBooking:guests.changingRoomsPickerLabel',
    defaultMessage: 'Number of changing rooms',
    description:
      'Guests: Label for selecting how many changing rooms will be in a Retreat booking',
  },
  childrenPickerLabel: {
    id: 'spaBooking:guests.childrenPickerLabel',
    defaultMessage: 'Children 2-13',
    description:
      'Guests: Label for selecting how many child guests will be in the booking',
  },
  continue: {
    id: 'spaBooking:guests.continue',
    defaultMessage: 'Continue',
    description: 'Guests: Button to continue to the next step from guests',
  },
  disclaimerLabel: {
    id: 'spaBooking:guests.disclaimerLabel',
    defaultMessage: '*Age limit',
    description: 'Guests: Age limit disclaimer',
  },
  disclaimerContent: {
    id: 'spaBooking:guests.disclaimerContent',
    defaultMessage:
      "Minimum age limit for entering the water is 2 years. This is due to young children being highly sensitive to the water's elevated mineral content.  \n\nWhat's included in free child pass: \n- Entrance for child 2-13 years \n- Towel \n- Silica mask \n- Shared locker access with parent/supervisor  \n\nNot included: \n- Individual locker \n- First drink free \n- Bathrobe",
    description: 'Guests: Age limit disclaimer',
  },
  disclaimerLabelRetreat: {
    id: 'spaBooking:guests.disclaimerLabelRetreat',
    defaultMessage: '*Age limit',
    description: 'Guests: Age limit disclaimer',
  },
  disclaimerContentRetreat: {
    id: 'spaBooking:guests.disclaimerContentRetreat',
    defaultMessage:
      "Minimum age limit for entering the water is 2 years. This is due to young children being highly sensitive to the water's elevated mineral content.  \n\nWhat's included in free child pass: \n- Entrance for child 2-13 years \n- Towel \n- Silica mask \n- Shared locker access with parent/supervisor  \n\nNot included: \n- Individual locker \n- First drink free \n- Bathrobe",
    description: 'Guests: Age limit disclaimer',
  },
  retreatNumberOfGuests: {
    id: 'spaBooking:guests.warnings.retreatNumberOfGuests',
    defaultMessage:
      'The Retreat Spa admission can only be booked for either 1 or 2 guests per booking. If you want to book Retreat Spa for more guests, please make a separate booking for each room booked.',
    description:
      'Guests: Notify that only 1 or 2 guests can be booked per booking',
  },
})

const warnings = defineMessages({
  floaties: {
    id: 'spaBooking:guests.warnings.floaties',
    defaultMessage:
      'Children aged 2-13 share a locker with parent/supervisor. Drink and robe is not included with a free child pass. Children 8 years and younger are required to use the arm floaties provided.',
    description:
      'Guests: Notify the required use of floaties for children 8 years and younger',
  },
  numberOfAdults: {
    id: 'spaBooking:guests.warnings.numberOfAdults',
    defaultMessage:
      'If you would like to book for more than {numberOfAdults} adults, please contact our service team to check for availability.',
    description: 'Guests: Notify the number of adults selected',
  },
})

const errors = defineMessages({
  tooManyChildren: {
    id: 'spaBooking:guests.errors.tooManyChildren',
    defaultMessage:
      'Too many children. Each adult can only be accompanied by up to 2 children.',
    description: 'Guests: Error when too many children are selected',
  },
  tooManyGuests: {
    id: 'spaBooking:guests.errors.tooManyGuests',
    defaultMessage: 'Too many guests.',
    description: 'Guests: Too many guests when using membership card',
  },
  winterCardGuestRestriction: {
    id: 'spaBooking:guests.errors.winterCardGuestRestriction',
    defaultMessage:
      'Winter card is only valid for {numberOfAdults} {numberOfAdults, plural, one {adult} other {adults}} and {numberOfChildren} {numberOfChildren, plural, one {child} other {children}}.',
    description:
      'Guests: Error when there are too many adults when using winter card',
  },
  summerCardGuestRestriction: {
    id: 'spaBooking:guests.errors.summerCardGuestRestriction',
    defaultMessage:
      'Summer card is only valid for {numberOfAdults} {numberOfAdults, plural, one {adult} other {adults}} and {numberOfChildren} {numberOfChildren, plural, one {child} other {children}}.',
    description:
      'Guests: Error when there are too many adults when using summer card',
  },
  winterCardGuestRestrictionRetreat: {
    id: 'spaBooking:guests.errors.winterCardGuestRestrictionRetreat',
    defaultMessage:
      'Winter card is only valid for {numberOfAdults} {numberOfAdults, plural, one {adult} other {adults}}.',
    description:
      'Guests: Error when there are too many adults when using winter card',
  },
  summerCardGuestRestrictionRetreat: {
    id: 'spaBooking:guests.errors.summerCardGuestRestrictionRetreat',
    defaultMessage:
      'Summer card is only valid for {numberOfAdults} {numberOfAdults, plural, one {adult} other {adults}}.',
    description:
      'Guests: Error when there are too many adults when using summer card',
  },
  missingAdult: {
    id: 'spaBooking:guests.errors.missingAdult',
    defaultMessage: 'There needs to be at least one adult.',
    description: 'Guests: Error when adults < 1',
  },
})

export const guestsMessages = {
  info,
  warnings,
  errors,
}
