import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { isBefore } from 'date-fns/isBefore'
import { isDate } from 'date-fns/isDate'
import { startOfToday } from 'date-fns/startOfToday'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import { rgba } from 'polished'

import { InfoBox } from 'bl-common/src/booking/InfoBox/InfoBox'
import { colors } from 'bl-common/src/constants/colors'
import { useBreakpoints } from 'bl-common/src/hooks/useBreakpoints'
import { theme } from 'bl-common/src/styles/theme'
import { ScreenTheme } from 'bl-common/src/styles/types'
import {
  buildBreadcrumbField,
  buildCalendarField,
  buildCustomField,
  buildHeading,
  buildScreenErrorField,
  buildScreenWithImageLayout,
} from 'bl-flows-core'
import {
  CartItem,
  CartItemType,
  DayAvailability,
  useDayAvailabilityQuery,
} from 'bl-graphql'
import { triggerEvent } from 'bl-utils/src/analytics/events'
import { getEndDate } from 'bl-utils/src/date'
import {
  displayDate,
  formatDateInUTC,
} from 'bl-utils/src/formatting/formatDate'
import { MembershipCartType } from 'bl-utils/src/membership'

import { globalBookingMessages } from '../../../messages'
import { getSpaImageLayoutProps } from '../../../utils'
import SubscriptionCardAccepted from '../components/SubscriptionCardAccepted'
import { calendarMessages } from '../messages/calendar'
import { commonMessages } from '../messages/common'
import { getSpaSummerAndWinterCardDateRange } from '../utils'

const isDefined = (value: unknown) => typeof value !== 'undefined'
const requiresReset = (
  previousValue: number,
  nextValue: number,
  cartItems: CartItem[]
) =>
  isDefined(previousValue) &&
  !isEqual(previousValue, nextValue) &&
  cartItems.some(
    item =>
      item.type === CartItemType.Massage ||
      item.type === CartItemType.Restaurant ||
      item.type === CartItemType.Transfer
  )

export const calendarScreen = ({
  screenTheme,
  admissionType = 'comfort',
}: {
  screenTheme: ScreenTheme
  admissionType?: string
}) => {
  return buildScreenWithImageLayout({
    id: 'calendar',
    subType: 'form',
    theme: screenTheme,
    route: () => {
      return {
        en: 'date',
        is: 'dagsetning',
      }
    },
    queryParams: control => {
      return {
        arrivalDate: control?.flow?.state?.calendar?.arrivalDate
          ? formatDateInUTC(control?.flow?.state?.calendar?.arrivalDate)
          : formatDateInUTC(new Date()),
      }
    },
    layoutProps: control =>
      getSpaImageLayoutProps(admissionType ?? 'comfort', 'calendar', control),
    setupHook: control => {
      const isMembership =
        control.flow.setupHook?.cart?.membership?.membershipToken != null
      const { mediaBmd } = useBreakpoints()

      const today = startOfToday()
      let endDay = getEndDate(today)

      // The end date for the calendar needs to be set when the membership ends.
      // Otherwise it's set to today + 12 months.
      if (isMembership) {
        const { summerCardDateRange, winterCardDateRange } =
          getSpaSummerAndWinterCardDateRange(control.context.spaFlowSettings)
        const membershipType = control.flow.setupHook?.cart?.membership?.type

        if (
          membershipType === MembershipCartType.WINTER_CARD &&
          winterCardDateRange?.fields?.to
        ) {
          endDay = new Date(winterCardDateRange?.fields?.to)
        } else if (
          membershipType === MembershipCartType.SUMMER_CARD &&
          summerCardDateRange?.fields?.to
        ) {
          endDay = new Date(summerCardDateRange?.fields?.to)
        }
      }

      const startDay = today
      const { loading, data } = useDayAvailabilityQuery({
        variables: {
          startDate: formatDateInUTC(startDay),
          endDate: formatDateInUTC(endDay),
        },
      })

      const [dayAvailability, setDayAvailability] = useState<{
        [key: string]: DayAvailability
      } | null>(null)
      const [excludedDates, setExcludedDates] = useState<Date[]>([])

      // create object with all available days as keys
      useEffect(() => {
        if (data && !loading) {
          const soldOutDays = data.dayAvailability
            .filter(day => day.soldOut)
            .map(soldOutDay => new Date(soldOutDay.date))

          const availableDays = data.dayAvailability.reduce((acc, day) => {
            if (!day.soldOut) {
              return { ...acc, [day.date]: day }
            }
            return acc
          }, {})

          setDayAvailability(availableDays)
          setExcludedDates(soldOutDays)
        }
      }, [data, loading])

      return {
        excludedDates,
        dayAvailability,
        isDayAvailabilityLoading: loading,
        endDay,
        mediaBmd,
      }
    },
    breadcrumb: control => {
      return {
        title: control.context.t(calendarMessages.info.breadcrumbTitle),
        value:
          !!control.flow.state.calendar?.arrivalDate &&
          control.context.t(calendarMessages.info.breadcrumbSubtitle, {
            formattedDate: displayDate(
              new Date(control.flow.state.calendar?.arrivalDate),
              { locale: control.context.locale }
            ),
          }),
      }
    },
    fields: {
      main: [
        buildBreadcrumbField({
          props: {
            breadcrumb: control => control.screen.breadcrumb,
          },
          layout: {
            spacing: {
              mb: { xs: 3 },
            },
          },
        }),
        buildCustomField({
          condition: control => {
            return (
              control.flow.setupHook?.cart?.membership?.membershipToken != null
            )
          },
          props: {
            render: () => {
              const { formatMessage } = useIntl()
              return (
                <SubscriptionCardAccepted
                  acceptedText={formatMessage(
                    globalBookingMessages.text.winterCardAccepted
                  )}
                  removeButtonLabel={formatMessage(
                    globalBookingMessages.buttons.remove
                  )}
                />
              )
            },
          },
        }),
        ...buildHeading({
          title: control => control.context.t(calendarMessages.info.title),
          subTitle: control =>
            control.context.t(calendarMessages.info.subtitle),
          removeMarginTop: true,
        }),
        buildScreenErrorField({
          props: {},
        }),
        buildCustomField({
          defaultValue: null,
          condition: control =>
            !control.screen.setupHook?.mediaBmd &&
            control.screen.setupHook.flowScreen,
          props: {
            component: props => {
              const spaFlowSettings =
                props.control.context?.spaFlowSettings?.find(flowSettings => {
                  return flowSettings?.fields?.admissionType === admissionType
                })

              const flowScreen = spaFlowSettings?.fields?.flowScreens?.find(
                screen => screen?.fields?.screenId === 'calendar'
              )

              if (!flowScreen?.fields?.infoBox) {
                return null
              }

              const infoBoxProps =
                spaFlowSettings.fields?.admissionType === 'retreat'
                  ? {
                      backgroundColor: rgba(colors.darkmodeOffset, 0.6),
                      textColor: colors.white,
                    }
                  : {}

              return (
                <div
                  style={{
                    paddingTop: theme.spacing[1],
                    paddingBottom: theme.spacing[2.5],
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing[1],
                  }}
                >
                  <InfoBox
                    title={''}
                    content={flowScreen.fields.infoBox}
                    {...infoBoxProps}
                  />
                </div>
              )
            },
          },
        }),
        buildCalendarField({
          id: 'arrivalDate',
          props: {
            themeStyle: theme?.bookingEngine?.[screenTheme]?.calendarField,
            ariaLabelForFullyBooked: control =>
              control.context.t(
                globalBookingMessages.text.timeSlotsFullyBooked
              ),
            isLoading: control =>
              control.screen.setupHook?.isDayAvailabilityLoading,
            excludedDates: control => control.screen.setupHook?.excludedDates,
            selected: control =>
              control.flow.state?.calendar?.arrivalDate || null,
            endDate: control => control.screen.setupHook?.endDay,
            onClick: async (arrivalDateInString, control) => {
              const arrivalDate = new Date(arrivalDateInString)

              if (
                control.flow.setupHook?.cart?.items?.length &&
                requiresReset(
                  new Date(
                    control.flow.stateRef.current.calendar?.arrivalDate
                  ).setUTCHours(0, 0, 0),
                  arrivalDate.setUTCHours(0, 0, 0),
                  control.flow.setupHook?.cart?.items
                )
              ) {
                const shouldContinue = await control.confirm(
                  control.context.t(commonMessages.warnings.modalResetState),
                  {
                    confirmLabel: control.context.t(
                      commonMessages.info.modalContinue
                    ),
                    cancelLabel: control.context.t(
                      commonMessages.info.modalCancel
                    ),
                    title: control.context.t(
                      commonMessages.info.modalTitleUpdateBooking
                    ),
                  }
                )

                if (!shouldContinue) {
                  return
                }

                control.flow.setState(
                  {
                    ...pick(control.flow.stateRef.current, ['guests']),
                  },
                  true
                )
              }

              control.screen.setState({
                arrivalDate: arrivalDateInString,
              })

              // We had an issue in Chrome 125.x that caused it crash when calling nextScreen
              // immediately after closing the modal + dispatching GA events, as if we are making the browser do too much.
              // The setTimeout seems to be a workaround for this issue for now.
              // TODO: Check if this still crashes in Chrome 126.x or newer.
              // Reproduction steps:
              // 1. Open /book/spa on production (or configure bluelagoon-web against prod to test in development mode)
              // 2. Add 2 adults.
              // 3. Choose 5th of June.
              // 4. Choose 15:00.
              // 5. Add lava reservation and confirm it.
              // 6. Go back to calendar screen (Date) and choose 12th of June.
              // 7. Click on Continue when the modal shows up about resetting your booking.
              // 8. Chrome should now crash with error code 5.

              setTimeout(() => {
                if (control.nextScreen()) {
                  const isRetreatSpaFlow =
                    control.flow.setupHook?.isRetreatSpaFlow

                  triggerEvent({
                    event: 'funnel_calendar',
                    BLType: isRetreatSpaFlow ? 'Day Visit Spa' : 'Day Visit',
                    pageTitle: 'Book Calendar',
                    pageCategory: 'book',
                    Adult: control.flow.state.guests?.adults || 1,
                    ...(!isRetreatSpaFlow && {
                      Children: control.flow.state.guests.children,
                    }),
                    Calendar: formatDateInUTC(arrivalDate, 'yyyy-MM-dd'),
                    language: control.context.locale,
                  })
                }
              }, 0)
            },
          },
          validation: {
            renderErrorInFieldRenderer: false,
            validator: (arrivalDateAsString: string, _, control) => {
              const arrivalDate = new Date(arrivalDateAsString)

              if (!arrivalDateAsString || !isDate(arrivalDate)) {
                return control.context.t(calendarMessages.errors.missingDate)
              }

              if (isBefore(arrivalDate, new Date().setUTCHours(0, 0, 0, 0))) {
                return control.context.t(calendarMessages.errors.invalidDate)
              }
            },
          },
          layout: {
            spacing: {
              mt: { xs: 1, bmd: 0 },
            },
          },
        }),
      ],
      rightBottom: [
        buildCustomField({
          defaultValue: null,
          props: {
            component: props => {
              const spaFlowSettings =
                props.control.context?.spaFlowSettings?.find(flowSettings => {
                  return flowSettings?.fields?.admissionType === admissionType
                })

              const flowScreen = spaFlowSettings?.fields?.flowScreens?.find(
                screen => screen?.fields?.screenId === 'calendar'
              )

              if (!flowScreen?.fields?.infoBox) {
                return null
              }

              const infoBoxProps =
                spaFlowSettings.fields?.admissionType === 'retreat'
                  ? {
                      backgroundColor: rgba(colors.darkmodeOffset, 0.6),
                      textColor: colors.white,
                    }
                  : {}

              return (
                <div
                  style={{
                    padding: theme.spacing[4],
                    display: 'flex',
                    flexDirection: 'column',
                    gap: theme.spacing[1],
                  }}
                >
                  <InfoBox
                    title={''}
                    content={flowScreen.fields.infoBox}
                    {...infoBoxProps}
                  />
                </div>
              )
            },
          },
        }),
      ],
    },
  })
}
