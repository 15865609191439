import { ReactNode, useEffect, useReducer } from 'react'
import styled, { useTheme } from 'styled-components'

import { colors } from 'bl-common/src/constants/colors'
import { CheckmarkIcon } from 'bl-common/src/elements/Icons/CheckmarkIcon'
import { Spinner } from 'bl-common/src/elements/Spinner'
import { SpinnerWrapper } from 'bl-common/src/elements/SpinnerWrapper'
import { Type } from 'bl-common/src/elements/Typography/Typography'
import { useBreakpoints } from 'bl-common/src/hooks/useBreakpoints'
import { ScreenTheme } from 'bl-common/src/styles/types'

enum CheckoutActionType {
  SetPaymentStatus = 'SET_PAYMENT_STATUS',
  SetOrderStatus = 'SET_ORDER_STATUS',
  SetConfirmStatus = 'SET_CONFIRM_STATUS',
}

type CheckoutProgress = {
  paymentSuccessful: boolean
  confirmSuccessful: boolean
  orderSuccessful: boolean
  busTransferSuccessful: boolean
  inProgress: boolean
  bookingRef?: string
  finished?: string
  error?: string
}

type CheckoutProgressProps = {
  checkoutStatus: CheckoutProgress
  translations: {
    payment: string
    order: string
    confirm: string
  }
  screenTheme?: ScreenTheme
}

type Status = 'pending' | 'inProgress' | 'complete'

const initialState: State = {
  paymentStatus: 'pending',
  orderStatus: 'pending',
  confirmStatus: 'pending',
}

type State = {
  paymentStatus: Status
  orderStatus: Status
  confirmStatus: Status
}

type Action = {
  type:
    | CheckoutActionType.SetPaymentStatus
    | CheckoutActionType.SetOrderStatus
    | CheckoutActionType.SetConfirmStatus
  status: Status
}

const statusReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case CheckoutActionType.SetPaymentStatus:
      return { ...state, paymentStatus: action.status }
    case CheckoutActionType.SetOrderStatus:
      return { ...state, orderStatus: action.status }
    case CheckoutActionType.SetConfirmStatus:
      return { ...state, confirmStatus: action.status }
    default:
      return state
  }
}

const StyledContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing[2],
}))

const StyledCheckoutEntryContainer = styled.div(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing[2],
  width: '100%',
}))

const StyledInnerContainer = styled.div<{
  screenTheme?: ScreenTheme
}>(({ screenTheme, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background:
    theme?.bookingEngine?.[screenTheme]?.checkoutProgress?.backgroundColor ||
    colors.white,
  padding: theme.spacing[1.5],
  boxShadow:
    theme?.bookingEngine?.[screenTheme]?.checkoutProgress?.boxShadow ||
    '0px 6px 30px 0px rgba(0, 0, 0, 0.1)',
  flex: 1,
}))

const LoadingIndicator = ({
  color,
  size,
  status,
}: {
  status: Status
  color: string
  size: number
}) => {
  return (
    <div>
      {status === 'complete' ? (
        <CheckmarkIcon width={size} color={color} />
      ) : (
        <SpinnerWrapper>
          <Spinner
            radius={size / 2}
            shouldAnimate={status === 'inProgress'}
            color={color}
          />
        </SpinnerWrapper>
      )}
    </div>
  )
}

const CheckoutEntry = ({
  status,
  children,
  screenTheme,
}: {
  status: Status
  children: ReactNode
  screenTheme?: ScreenTheme
}) => {
  const { isMobile } = useBreakpoints()
  const theme = useTheme()
  const themeStyle = theme?.bookingEngine?.[screenTheme]?.checkoutProgress
  const statusColor = {
    pending: themeStyle?.pendingColor || colors.formGrey,
    inProgress: themeStyle?.inProgressColor || colors.deepBlue,
    complete: themeStyle?.completeColor || colors.deepBlue,
  }
  return (
    <StyledCheckoutEntryContainer>
      {!isMobile && (
        <LoadingIndicator
          size={40}
          color={statusColor[status]}
          status={status}
        />
      )}
      <StyledInnerContainer screenTheme={screenTheme}>
        <Type weight="bold" color={statusColor[status]} preset="textLarge">
          {children}
        </Type>
        {isMobile && (
          <LoadingIndicator
            size={20}
            color={statusColor[status]}
            status={status}
          />
        )}
      </StyledInnerContainer>
    </StyledCheckoutEntryContainer>
  )
}

export const CheckoutProgressComponent = ({
  checkoutStatus,
  screenTheme,
  translations,
}: CheckoutProgressProps) => {
  const [state, dispatch] = useReducer(statusReducer, initialState)

  useEffect(() => {
    if (!checkoutStatus) {
      return
    }
    // Sequentially update the status based on checkoutStatus
    const updateSequentialStatus = () => {
      // Payment status
      const status = checkoutStatus.paymentSuccessful
        ? 'complete'
        : 'inProgress'
      dispatch({
        type: CheckoutActionType.SetPaymentStatus,
        status,
      })

      // Order status depends on payment success
      if (checkoutStatus.paymentSuccessful) {
        const status = checkoutStatus.orderSuccessful
          ? 'complete'
          : 'inProgress'
        dispatch({
          type: CheckoutActionType.SetOrderStatus,
          status,
        })

        // Confirm status depends on order success
        if (checkoutStatus.orderSuccessful) {
          const status = checkoutStatus.confirmSuccessful
            ? 'complete'
            : 'inProgress'
          dispatch({
            type: CheckoutActionType.SetConfirmStatus,
            status,
          })
        }
      }
    }

    updateSequentialStatus()
  }, [checkoutStatus])

  return (
    <StyledContainer>
      <CheckoutEntry status={state.paymentStatus} screenTheme={screenTheme}>
        {translations.payment}
      </CheckoutEntry>
      <CheckoutEntry status={state.orderStatus} screenTheme={screenTheme}>
        {translations.order}
      </CheckoutEntry>
      <CheckoutEntry status={state.confirmStatus} screenTheme={screenTheme}>
        {translations.confirm}
      </CheckoutEntry>
    </StyledContainer>
  )
}
