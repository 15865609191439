import { defineMessages } from 'react-intl'

const info = defineMessages({
  breadcrumbTitle: {
    id: 'spaBooking:bookingDetails.breadcrumbs.title',
    defaultMessage: 'Checkout',
    description: 'Booking Details: Title of booking details step breadcrumb',
  },
  title: {
    id: 'spaBooking:bookingDetails.title',
    defaultMessage: 'Booking details',
    description: 'Booking Details: Title of booking details step',
  },
  subtitle: {
    id: 'spaBooking:bookingDetails.subtitle',
    defaultMessage:
      'Total for your booking on *{formattedDate}:* {formattedPrice}',
    description: 'Booking Details: Subtitle of booking details step',
  },
  labelPhone: {
    id: 'spaBooking:bookingDetails.label.phone',
    defaultMessage: 'Phone number*',
    description: 'Booking Details: Label for phone',
  },
  placeholderFirstName: {
    id: 'spaBooking:bookingDetails.placeholder.firstName',
    defaultMessage: 'First name*',
    description: 'Booking Details: Placeholder for first name',
  },
  placeholderLastName: {
    id: 'spaBooking:bookingDetails.placeholder.lastName',
    defaultMessage: 'Last name*',
    description: 'Booking Details: Placeholder for last name',
  },
  placeholderEmail: {
    id: 'spaBooking:bookingDetails.placeholder.email',
    defaultMessage: 'Enter your email',
    description: 'Booking Details: Placeholder for email',
  },
  placeholderPhone: {
    id: 'spaBooking:bookingDetails.placeholder.phone',
    defaultMessage: 'Enter your phone number',
    description: 'Booking Details: Placeholder for phone',
  },
  buttonContinue: {
    id: 'spaBooking:bookingDetails.button.continue',
    defaultMessage: 'Agree & continue to payment',
    description: 'Booking Details: Agree & continue to payment button label',
  },
  buttonContinueNoPaymentRequired: {
    id: 'spaBooking:bookingDetails.button.continue.noPaymentRequired',
    defaultMessage: 'Agree & complete booking',
    description: 'Booking Details: Agree & finalize booking button label',
  },
})

const warnings = defineMessages({})

const errors = defineMessages({})

export const bookingDetailsMessages = {
  info,
  warnings,
  errors,
}
